import { Search } from "@mui/icons-material";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

const SearchComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
}));

export default function Accounts() {
  const { setSnackbar } = useOutletContext();
  const [customerId, setCustomerId] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (customerId === "") {
      setSnackbar({ children: "Enter Customer ID to Search!", severity: "error" })
    } else {
      navigate(`/accounts/${customerId}`);
    }
  };

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
    >
      <Toolbar />
      <Typography variant="h4">Accounts</Typography><br />
      <Box>
        <SearchComponent>
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search for Partner Customer ID"
            inputProps={{ 'aria-label': 'search' }}
            value={customerId}
            onChange={handleCustomerIdChange}
          />
        <Button variant="contained" onClick={handleSearch}>Search</Button>
        </SearchComponent>
      </Box>
    </Box>
  );
}