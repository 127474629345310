import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Toolbar, Typography, Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from '@mui/material/styles';
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { DEVICE_BINDING_QUERY, DEVICE_INFO_SEARCH_QUERY } from "../tivo_gql";
import DeviceBinding from "./DeviceBinding";
import DeviceInfo from "./DeviceInfo";

const SearchComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
}));

export default function Devices() {
  const { setSnackbar } = useOutletContext();
  const [hardwareSerialNumber, setHardwareSerialNumber] = useState("");
  const [tivoSerialNumber, setTivoSerialNumber] = useState("");
  const [getDeviceBinding, { called: bindingCalled, loading: bindingLoading, error: bindingError, data: bindingData }] = useLazyQuery(DEVICE_BINDING_QUERY,);
  const [deviceInfoSearch, { called: deviceInfoCalled, loading: deviceInfoLoading, error: deviceInfoError, data: deviceInfoData }] = useLazyQuery(DEVICE_INFO_SEARCH_QUERY,);
  const params = useParams();

  const handleGetBinding = () => {
    if (hardwareSerialNumber === "") {
      setSnackbar({ children: "Enter Hardware Serial Number ID to get binding!", severity: "error" })
    } else {
      getDeviceBinding({
        variables: {
          hardwareSerialNumber: hardwareSerialNumber
        }
      });
    }
  };

  const handleDeviceInfoSearch = () => {
    if (tivoSerialNumber === "") {
      setSnackbar({ children: "Enter Tivo Serial Number to Search Device Info!", severity: "error" })
    } else {
      deviceInfoSearch({
        variables: {
          tivoSerialNumber: tivoSerialNumber
        }
      });
    }
  };

  const handleHardwareSerialNumberChange = (event) => {
    setHardwareSerialNumber(event.target.value);
  }

  const handleTivoSerialNumberChange = (event) => {
    setTivoSerialNumber(event.target.value);
  }

  if (params.hardwareSerialNumber && !bindingCalled) {
    setHardwareSerialNumber(params.hardwareSerialNumber);
    getDeviceBinding({
      variables: {
        hardwareSerialNumber: params.hardwareSerialNumber
      }
    });
  }

  if (params.tivoSerialNumber && !deviceInfoCalled) {
    setTivoSerialNumber(params.tivoSerialNumber);
    deviceInfoSearch({
      variables: {
        tivoSerialNumber: params.tivoSerialNumber
      }
    })
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
    >
      <Toolbar />
      <Typography variant="h4">Devices</Typography><br />
      <Box>
        <SearchComponent>
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search for Hardware Serial Number"
            inputProps={{ 'aria-label': 'search' }}
            value={hardwareSerialNumber}
            onChange={handleHardwareSerialNumberChange}
          />
        <Button variant="contained" onClick={handleGetBinding}>Get Binding</Button>
        </SearchComponent>
      </Box>
      <br/>

      <Box>
        <SearchComponent>
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search for Tivo Serial Number"
            inputProps={{ 'aria-label': 'search' }}
            value={tivoSerialNumber}
            onChange={handleTivoSerialNumberChange}
          />
        <Button variant="contained" onClick={handleDeviceInfoSearch}>Search Device Info</Button>
        </SearchComponent>
      </Box>
      <br/>
      {bindingLoading ? <CircularProgress/> : (bindingData ? <DeviceBinding deviceBinding={bindingData.deviceBindingGet}/> : "")}
      {deviceInfoLoading ? <CircularProgress/> : (deviceInfoData ? <DeviceInfo deviceInfoSearch={deviceInfoData.deviceInfoSearch}/> : "")}

      {console.log('b-error: ', bindingError)}

      {bindingError &&
      <Paper sx={{p: 2}} >
        <Box sx={{p: 2}}>
          <Typography paragraph={true}>Error: Binding not found</Typography>
          {console.error(bindingError.message)}
      </Box>
      </Paper>
    
      }

      {deviceInfoError && 
      <Paper sx={{p: 2}}>
        <Box sx={{p: 2}}>
          <Typography paragraph={true}>Error: Tivo serial number not found</Typography>
          {console.error(deviceInfoError.message)}
        </Box>
        </Paper>
      
      }

    </Box>
  );
}