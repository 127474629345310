import { ApolloClient, ApolloLink, ApolloProvider, concat, gql, HttpLink, InMemoryCache, useQuery } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Alert, AppBar, Box, Button, CssBaseline, Snackbar, Toolbar, Typography, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import LoginStatus from './components/LoginStatus';

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

let csrf_token;

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-csrf-token': csrf_token
    }
  });
  return forward(operation).map(response => {
    const possible_csrf_token = operation.getContext().response.headers.get("x-csrf-token");
    if (possible_csrf_token) {
      csrf_token = possible_csrf_token;
    }
    return response;
  });
});

const httpLink = new HttpLink({
  uri: document.apiUrl || process.env.REACT_APP_API_URL,
  // credentials: 'same-origin',
  credentials: 'include'
});

const errhandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
    });
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, concat(errhandler, httpLink)),
});

const Q_SYNC = gql`
query { pingSync }
`;

export default function Layout(props) {
  const [snackbar, setSnackbar] = useState(null);
  const { loading, error } = useQuery(Q_SYNC, { client: client });

  if (loading) return <CircularProgress/>
  
  if (error) {
    return (<div>`We have an error: ${error.message}`</div>);
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Box>
          <CssBaseline />
          <AppBar position="fixed" sx={{ alignItems: 'left', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <Typography variant="h5" noWrap component="div">
                <Link id="home-link" to="/">Tivo Admin UI</Link>
              </Typography>
              <Button sx={{pl: 3}} component={NavLink} to="/">Account Summary</Button>
              <Button component={NavLink} to="/devices">Devices</Button>
              <Button component={NavLink} to="/device-management" >Device Management</Button>
              <Button component={NavLink} to="/database-info" sx={{justifyContent: "flex-start", flexGrow: 1}}>Database Information</Button>
              <LoginStatus/>
            </Toolbar>
          </AppBar>
        </Box>
        <Outlet context={{ setSnackbar, snackbar }} />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={() => setSnackbar(null)}
            autoHideDuration={4000}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} variant="filled" />
          </Snackbar>
        )}
      </ThemeProvider>
    </ApolloProvider>
  );
}
