import { Box, Typography, Paper, CircularProgress } from "@mui/material";

const AccountEntitlements = ({params, data, loading, error}) => {
    
    if (loading) return <Paper sx={{p: 3, m: 2} }><Box><div className="loading">Loading LINPUB Account Entitlements...</div><CircularProgress/></Box></Paper>

    if(error) return (
      <Box component="main" sx={{ flexGrow: 1, p: 3}}>
        {error.message}
      </Box>
    )
    if(data) {
      console.log('linpub ', data )
      const entitlement = data.linpubAccountEntitlementSearch.accountEntitlement;

      return (
        <>
        <h1 style={{paddingLeft: '20px'}}>Account #{params.customerId}</h1>

        <Paper sx={{p: 3, m: 2}}>
        <Box>
            <Typography variant="h5">LINPUB Account Entitlements</Typography>
            {entitlement === null ? <Typography sx={{marginTop: '10px'}}>No entitlements found</Typography> : 
              !entitlement.linearPackageReference.length ? <Typography sx={{marginTop: '10px'}}>No entitlements found</Typography> : 
              <ul>
                  {entitlement.linearPackageReference.map((pkg, idx) => <li key={`pkg-${idx}`}>{pkg.partnerPackageId}</li>)}
              </ul>
            }
        </Box>
        </Paper>
        </>
      )
    }
  
} 

export default AccountEntitlements