import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Accounts from './components/Accounts';
import Devices from './components/Devices';
import Layout from './Layout';

import './App.scss';
import AccountSummary from './components/AccountSummary';
import DeviceManagement from './components/DeviceManagement';
import DatabaseInfo from './components/DatabaseInfo';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Accounts/>} />
          <Route path="accounts" element={<Accounts/>}/>
          <Route path="accounts/:customerId" element={<AccountSummary/>}/>
          <Route path="database-info" element={<DatabaseInfo/>}/>
          <Route path="devices" element={<Devices/>}>
            <Route path="binding/:hardwareSerialNumber" element={<Devices/>} />
            <Route path="deviceInfo/:tivoSerialNumber" element={<Devices/>} />
          </Route>
          <Route path="device-management" element={<DeviceManagement/>}/>
        </Route>
      </Routes> 
    </BrowserRouter>
  );
}
