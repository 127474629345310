
import { useQuery } from "@apollo/client";
import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { FEATURE_ENABLEMENT_PROVISION } from "../tivo_gql";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const FeatureEnablement = ({params}) => {
    const {loading, error, data } = useQuery(FEATURE_ENABLEMENT_PROVISION, {
      variables: { partnerCustomerId: params.customerId }
    });
    
    if (loading) return <Paper sx={{p: 3, m: 2} }><Box><div className="loading">Loading Feature Enablement Provisioning...</div><CircularProgress/></Box></Paper>

    if(error) return (
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {error.message}
      </Box>
    )
  
    if(data) {
      const featureEnablement = data.feAccountGet;

    return (
        <Paper sx={{p: 3, m: 2} }>
        <Box>
            <Typography variant="h5">Feature Enablement Provisioning</Typography>
            <Typography variant="h6">Packages</Typography>
            {featureEnablement.accountAlaCartePackage === null ? <Typography sx={{marginTop: '10px'}}>No packages found</Typography> :

            !featureEnablement.accountAlaCartePackage.length ? <Typography sx={{marginTop: '10px'}}>No packages found</Typography> : 
                <ul>
                    {featureEnablement.accountAlaCartePackage?.map((pkg, idx) => <li key={`pkg-${idx}`}>{pkg}</li>)}
                </ul>
            }
            
            {featureEnablement.provisionedDevices == null ? <Typography sx={{marginTop: '10px'}}>No active devices found</Typography> :
            
            !featureEnablement.provisionedDevices.filter(device => device.serviceState === "active").length ? 
            <Typography sx={{marginTop: '10px'}}>No active devices found</Typography> : 
            <>
            <Typography variant="h6">Devices</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>Device Type</TableCell>
                    <TableCell>Service State</TableCell>
                    <TableCell>Body ID</TableCell>
                    <TableCell>Packages</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {featureEnablement.provisionedDevices
                        .filter(device => device.serviceState === "active")
                        .map((device) => (
                    <TableRow
                        key={device.tsn}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{device.deviceType}</TableCell>
                        <TableCell>{device.serviceState}</TableCell>
                        <TableCell>{device.tsn.replace(/tsn:/, "")}</TableCell>
                        <TableCell>{device.devicePackages.join(", ")}</TableCell>
                    </TableRow>
                    ))
                    
                    }
                </TableBody>
                </Table>
            </TableContainer>
            </>
            }
          
        </Box>
        </Paper>
        )
    }
}

export default FeatureEnablement

