import * as React from 'react';
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import { useMutation } from "@apollo/client";
import { Paper, Box, Button, CircularProgress, Typography,TextField} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import { DEVICE_ACCOUNT_FIX } from "../tivo_gql";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceManagement = () => {
    const [accountId, setAccountId] = useState("")
    const [fipsCode, setFipsCode] = useState("")
    const [accountIdError, setAccountIDError] = useState("");
    const [fipsCodeError, setFipsCodeError] = useState("");
    const [open, setOpen] = useState(false);

    const [deviceAccountFix, { loading, error, data }] = useMutation(DEVICE_ACCOUNT_FIX);
  
    const handleClose = () => {
      setOpen(false);
      window.location.reload(false);
    };

    const fixAccount = () => {
        if(accountId === "") {
          setAccountIDError("Account ID Cannot Be Blank!")
        } 

        if(fipsCode === "") {
          setFipsCodeError("Fips Code Cannot Be Blank!")
        } 

        if(accountId !== "" && fipsCode !== "") {

          setOpen(true);

          setAccountIDError("")
          setFipsCodeError("") 

          let dirtyId = accountId;
          let dirtyFips = fipsCode;

          //Remove all non-numericals
          let id = dirtyId.replace(/\D/g,'');
          let fips = dirtyFips.replace(/\D/g,'');

          if(id !== "" && fips !== "") deviceAccountFix({variables: { accountId: id, fipsCode: fips }});
        }
    }
 
    if (loading) return <Paper sx={{p: 3, m: 2, mt: 5} }><Box><div className="loading">Fixing Account ...</div><CircularProgress/></Box></Paper>

    if(error && error.message === "resync connection") window.location.reload(false);
    if(error && error.message === "token cannot be renewed, must auth again") window.location.reload(false);

    if(error && error.message === "account_not_found") {
      return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >

            <DialogTitle><ErrorIcon sx={{color: "red", position: "relative", top: "4px"}}  /> {"Error"}</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              Account Not Found
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
          </Dialog> 
      ) 
    }

    if(error && error.message !== "account_not_found") {
      <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >

            <DialogTitle><ErrorIcon sx={{color: "red", position: "relative", top: "4px"}}  /> {"Error"}</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              {error && error.message}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
      </Dialog>

    }

    
    if(data && data.accountFix === "account_fix_scheduled") {
        return (
        <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >

                <DialogTitle><InfoIcon sx={{color: "#1976d2", position: "relative", top: "4px"}}  /> {"Attention"}</DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                  Account fix has been scheduled it will be processed shortly, please use the account summary 
                  tab and refresh the page to see corrected values. Please note that this can take up to 5 minutes to complete.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
              </Dialog>
        )
    } else {

    return (
      <>
      <Typography sx={{p: 3, pb: 3, mt: 8}} variant="h4">Device Management</Typography>
      <Box sx={{maxWidth: "500px"}}>
        <FormGroup sx={{p: 3, mb: 3}}>
          <TextField 
            type="number"
            id="outlined-basic" 
            placeholder="Account Number" 
            label="Account Number" 
            variant="outlined" 
            value={accountId}
            onChange={e => setAccountId(e.target.value)} />

            {accountIdError !== "" ? `Error:  ${accountIdError}` : null}

            <TextField 
              type="number"
              sx={{mt: 2}}
              id="outlined-basic" 
              placeholder="Fips Code" 
              label="Fips Code" 
              variant="outlined" 
              value={fipsCode}
              onChange={e => setFipsCode(e.target.value)} />

              {fipsCodeError !== "" ? `Error:  ${fipsCodeError}` : null}
            
            <Button sx={{mt: 2}} type="submit" variant="contained" onClick={fixAccount}>Fix Account</Button>

          </FormGroup>

      </Box>
      </>
      )
    }
}

export default DeviceManagement

