import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Paper, Box, Button, CircularProgress, Typography,TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/Error';
import FormGroup from '@mui/material/FormGroup';
import { DATABASE_INFO } from "../tivo_gql";
import { useOutletContext } from "react-router-dom";
import moment from "moment";


const DatabaseInfo = () => {
    const {setSnackbar} = useOutletContext();
    const [accountId, setAccountId] = useState("");

    const [databaseInfo, { loading, error, data }] = useLazyQuery(DATABASE_INFO);

    const localTime = (utcTime) => {
      let local_date= moment.utc(utcTime).local().format('ddd MM-DD-YYYY hh:mm a');
      return local_date
    }
      
    const handleSubmit = () => {
      if(accountId !== "") {
        let regex = /\D/;
        let hasInvalidChars = regex.test(accountId);

        if(hasInvalidChars) {
          setSnackbar({ children: "Account ID Field Must Include Only Number Characters!", severity: "error" })
        } else {
          let id = accountId;
          id = id.replace(/\D/g,''); 
          
          databaseInfo({
            variables: {accountId : id},
            onCompleted: (data) => {
              setSnackbar({ children: "Success! Account Information Found.", severity: "success" })
            }
          },
          
          )}
          
      } else {
        setSnackbar({ children: "Account ID Field Must Not Be Empty!", severity: "error" })
      }
    }

    
    if(loading) return <Paper sx={{p: 3, m: 2, mt: 5} }><Box><div className="loading">Loading...</div><CircularProgress/></Box></Paper>
    if(error && error.message === "resync connection") window.location.reload(false);
    if(error && error.message === "token cannot be renewed, must auth again") window.location.reload(false);

    if(data)  {
      //Logging
      console.log(data)

      const backToDBSearch = () => {
        //reload form
        window.location.reload(false);
      }

      if(data.accountGet) {
        const dbInfo = data.accountGet;

        return (
        <>
        <Typography onClick={backToDBSearch} sx={{p: 3, pb: 3, mt: 8, "&:hover" : {cursor: "pointer", textDecoration: "underline"}}} paragraph={true}>
          <SearchIcon sx={{position: "relative", top: "5px"}} /> Back to search
        </Typography>

        <Typography sx={{p: 3, pb: 3, mt: 2}} variant="h4">Database Information</Typography>

        <Paper sx={{m: 2}}>
          <Box sx={{p: 2}}>
          <Typography sx={{p: 3}} variant="h6">Account Information</Typography>

          <ul>
            <li>Account ID: {dbInfo.accountId ? dbInfo.accountId : "n/a"}</li>
            <li>Customer ID: {dbInfo.customerId ? dbInfo.customerId : "n/a"}</li>
            <li>Active STB Equipment: {dbInfo.hasActiveStbEquipment ? dbInfo.hasActiveStbEquipment : "n/a"}</li>
            <li>Has IP Equipment: {dbInfo.hasIpEquipment ? "true" : "false"}</li>
            <li>Status: {dbInfo.status ? dbInfo.status : "n/a"}</li>
          </ul>

          </Box>
        </Paper>

        {dbInfo.equipment == null ? <Paper sx={{m: 2}}><Box sx={{p: 2}}><Typography sx={{p: 3, ml: 3}} variant="h6">Equipment</Typography><Typography sx={{marginTop: '10px'}}>No active equipment found</Typography></Box></Paper> : 
        !dbInfo.equipment.length ? <Paper sx={{m: 2}}><Box sx={{p: 2}}><Typography sx={{p: 3, ml: 3}} variant="h6">Equipment</Typography><Typography sx={{marginTop: '10px'}}>No active equipment found</Typography></Box></Paper>:
        <Paper sx={{m: 2}}>
          <Box sx={{p: 2}}>
            <Typography sx={{p: 3}} variant="h6">Equipment</Typography>
            
              {dbInfo.equipment.map((db, key) => (
                <ul key={key}>
                  <li>Equipment Serial Number: {db.equipmentSerialNumber ? db.equipmentSerialNumber : "n/a"}</li>
                  <li>Primary Address: {db.primaryAddress ? db.primaryAddress : "n/a"}</li>
                  <li>Status: {db.status ? db.status : "n/a"}</li>
                  <li>Type: {db.type ? db.type : "n/a"}</li>
                  <li>Last Check-in: {db.updatedAt ? localTime(db.updatedAt) : "n/a"}</li>
                </ul>
              ))}
          
          </Box>
        </Paper>
        }

        {dbInfo.packages == null ? <Paper sx={{m: 2}}><Box sx={{p: 2}}><Typography sx={{p: 3}} variant="h6">Packages</Typography><Typography sx={{mt: '10px', ml: 3}}>No packages found</Typography></Box></Paper> :
        !dbInfo.packages.length ? <Paper sx={{m: 2}}><Box sx={{p: 2}}><Typography sx={{p: 3}} variant="h6">Packages</Typography><Typography sx={{mt: '10px', ml: 3}}>No packages found</Typography></Box></Paper> :
        <Paper sx={{m: 2}}>
          <Box sx={{p: 2}}>
            <Typography sx={{p: 3,}} variant="h6">Packages</Typography>
            <ul>
              {dbInfo.packages.map((db, key) => (
                <li key={key}>{db}</li>
              ))}
            </ul>

          </Box>
        </Paper>
        }
        </>
        )
   
        }         

    } else {
      return (
        <>
        <Typography sx={{p: 3, pb: 3, mt: 8}} variant="h4">Database Information</Typography>
        <Box sx={{maxWidth: "500px"}}>
          <FormGroup sx={{p: 3, mb: 3}}>
            <TextField 
              type="number"
              id="outlined-basic" 
              placeholder="Account Number" 
              label="Account Number" 
              variant="outlined" 
              value={accountId}
              onChange={e => setAccountId(e.target.value)} />
              
              <Button sx={{mt: 2}} type="submit" variant="contained" onClick={handleSubmit}>Get All Info</Button>
                
              {error && error.message === "account_not_found" ? 
              <Typography sx={{color: "red", fontWeight: "bold", pt:3}} paragraph={true}><ErrorIcon sx={{position: "relative", top: "6px" }} /> Account Not Found</Typography> : error && error.message &&
              <Typography sx={{color: "red", fontWeight: "bold", pt:3}} paragraph={true}><ErrorIcon sx={{position: "relative", top: "6px" }} /> {error && error.message}</Typography>}
    
            </FormGroup>
  
        </Box>
        </>
        )
      }

    }


export default DatabaseInfo

