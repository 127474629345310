import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";

export default function DeviceBinding(props) {
  if (props.deviceBinding) {
    const deviceBinding = props.deviceBinding;

    return (
      <Paper sx={{p: 3}}>
        <Typography variant="h4">Device #{deviceBinding.hardwareSerialNumber}</Typography><br/>
        <Box>
          <strong>Create Date</strong>: {deviceBinding.dateBound ? moment(deviceBinding.dateBound).utc().format('MM-DD-YYYY') : "n/a"}<br/>
          <strong>License Plate Code</strong>: {deviceBinding.licensePlateCode ? deviceBinding.licensePlateCode : "n/a"}<br/>
          <strong>Partner Customer ID</strong>: {deviceBinding.partnerCustomerId ? deviceBinding.partnerCustomerId : "n/a"}<br/>
          <strong>Partner ID</strong>: {deviceBinding.partnerId ? deviceBinding.partnerId : "n/a"}<br/>
          <strong>Refresh Token</strong>: {deviceBinding.refreshToken ? deviceBinding.refreshToken : "n/a" }<br/>
          <strong>Type</strong>: {deviceBinding.type ? deviceBinding.type : "n/a"}<br/>
          <strong>Last Updated</strong>: {deviceBinding.bindingLastUpdated ? moment(deviceBinding.bindingLastUpdated).utc().format('MM-DD-YYYY') : "n/a"}<br/>
        </Box>
      </Paper>
    );
  } else {
    return "Device not found";
  }
}