import { Box, Toolbar, Paper, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { LINPUB_ACCOUNT_ENTITLEMENT_SEARCH } from "../tivo_gql";

import AccountEntitlements from "./AccountEntitlements";
import FeatureEnablement from "./FeatureEnablement";
import TrinityCustomerInfo from "./TrinityCustomerInfo";
import PpsAccountStatus from "./PpsAccountStatus";

export default function AccountSummary() {
  const params = useParams();

  const {loading, error, data } = useQuery(LINPUB_ACCOUNT_ENTITLEMENT_SEARCH, {
    variables: { partnerCustomerId: params.customerId }
  })

  if (loading) return <Paper sx={{p: 3, m: 2} }><Box><CircularProgress/></Box></Paper>

  if(error) return (
    <Paper sx={{p: 3, m: 3, marginTop: '100px'} }>
      <Box component="main" sx={{ flexGrow: 1, p: 3}}>
        <h1>Account Not Found</h1>
        {console.error(error.message)}
      </Box>
    </Paper>
  )
  if(data) {
  
      return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />

            {/* Account Entitlements*/}
            <AccountEntitlements params={params} loading={loading} error={error} data={data} />

            {/* Feature Enablement Provision */}
            <FeatureEnablement params={params} />

            {/* Trinity Customer Info */} 
            <TrinityCustomerInfo params={params} />

            {/* PPS Account Status */}
            <PpsAccountStatus params={params} />

        </Box>
      );
  }
}