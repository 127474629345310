import { Box, Paper, Typography } from "@mui/material";

export default function DeviceInfo(props) {
  if (props.deviceInfoSearch) {
    console.log(props)

    const deviceInfoSearch = props.deviceInfoSearch.deviceInfo;

    return (
      <Paper sx={{p: 3}}>
        <Typography variant="h4">Device #{deviceInfoSearch.contract.device.tivoSerialNumber}</Typography><br/>
        <Box>
          <Typography variant="h5">Device Info</Typography>
          <strong>Device Type</strong>: {deviceInfoSearch.deviceType}<br/>
          <strong>Partner ID</strong>: {deviceInfoSearch.partnerId}<br/>
          <strong>Name</strong>: {deviceInfoSearch.name}<br/>
          <strong>hardwareSerialNumber</strong>: {deviceInfoSearch.hardwareSerialNumber}<br/>
          <strong>Device Type</strong>: {deviceInfoSearch.deviceType}<br/>
          <strong>Override Auto-Discovery</strong>: {deviceInfoSearch.overrideAutoDiscovery}<br/>
          <strong>Caller ID</strong>: {deviceInfoSearch.callerId}<br/>
          <strong>MSO Service ID</strong>: {deviceInfoSearch.msoServiceId}<br/>
          <br/>
          <Typography variant="h6">Contract</Typography>
          <strong>Activation Date</strong>: {deviceInfoSearch.contract.activationDate}<br/>
          <strong>Status</strong>: {deviceInfoSearch.contract.status}<br/>
          <br/>
          <strong><u>Device</u></strong><br/>
          <strong>CA Device ID</strong>: {deviceInfoSearch.contract.device.caDeviceId}<br/>
          <strong>Device Type</strong>: {deviceInfoSearch.contract.device.deviceType}<br/>
          <strong>Hardware Serial Number</strong>: {deviceInfoSearch.contract.device.hardwareSerialNumber}<br/>
          <strong>Partner ID</strong>: {deviceInfoSearch.contract.device.partnerId}<br/>
          <strong>Sub Partner ID</strong>: {deviceInfoSearch.contract.device.subPartnerId}<br/>
          <strong>Tivo Serial Number</strong>: {deviceInfoSearch.contract.device.tivoSerialNumber}<br/>

          <br/>
          <strong><u>Feature Param List</u></strong><br/>
          <ul>
            {deviceInfoSearch.featureParamList.map(fp => (
                <li key={fp.key}>
                  {fp.key}: {fp.value}
                </li>
              ))}
          </ul>
        </Box>
      </Paper>
    );
  } else {
    return "Device not found";
  }
}