import { gql } from "@apollo/client";

// QUERIES
export const DATABASE_INFO = gql `
  query AccountGet($accountId: String!) {
    accountGet(accountId: $accountId) {
      accountId,
      customerId,
      equipment {
        equipmentSerialNumber,
        insertedAt,
        primaryAddress,
        status,
        type,
        updatedAt
      },
      hasActiveStbEquipment,
      hasIpEquipment,
      packages,
      status
    }
  }
`;

export const LINPUB_ACCOUNT_ENTITLEMENT_SEARCH = gql `
  query LinpubAccountEntitlementSearch($partnerCustomerId: String!) {
    linpubAccountEntitlementSearch(partnerCustomerId: $partnerCustomerId) {
      accountEntitlement {
        linearPackageReference {
          partnerPackageId
        },
        partnerCustomerId
      },
      isBottom,
      isTop
    }
  }
`;

export const FEATURE_ENABLEMENT_PROVISION = gql `
  query FeAccountGet($partnerCustomerId: String!) {
    feAccountGet(partnerCustomerId: $partnerCustomerId) {
      accountAlaCartePackage,
      provisionedDevices {
        deviceType,
        serviceState,
        tsn,
        devicePackages
        deviceProvisioned
      }
    }
  }
`;

export const TRINITY_CUSTOMER_INFO = gql `
  query TrinityCustomerInfoSearch($partnerCustomerId: String!, $contractStatus: String!) {
    trinityCustomerInfoSearch(partnerCustomerId: $partnerCustomerId, contractStatus: $contractStatus) {
      customerList {
        contractList {
          status,
          device {
              hardwareSerialNumber,
              tivoSerialNumber
          }
        }
      }
    }
  }
`;

export const PPS_ACCOUNT_STATUS = gql `
  query PpsAccountStatusGet($accountId: String!) {
    ppsAccountStatusGet(accountId: $accountId) {
      accountId,
      customerId,
      status
    }
  }
`;

export const PPS_CHECKED_IN_DEVICES = gql `
  query GetCheckedInDevices($accountId: String!) {
    getCheckedInDevices(accountId: $accountId) {
      deviceType,
      insertedAt,
      serialNumber,
      status,
      updatedAt
    }
  }
`;

export const DEVICE_BINDING_QUERY = gql `
query DeviceBindingGet($hardwareSerialNumber: String!) {
  deviceBindingGet(hardwareSerialNumber: $hardwareSerialNumber) {
    bindingLastUpdated
    dateBound
    hardwareSerialNumber
    licensePlateCode
    partnerCustomerId
    partnerId
    refreshToken
    type
  }
}
`;

export const DEVICE_INFO_SEARCH_QUERY = gql`
query DeviceInfoSearch($tivoSerialNumber: String!) {
  deviceInfoSearch(tivoSerialNumber: $tivoSerialNumber) {
    requestId
    status
    deviceInfo {
      contract {
        status
        device {
          caDeviceId
          tivoSerialNumber
        }
        startDate      
      }
      featureParamList {
          type
          key
          value
      }
      partnerId
      name
      hardwareSerialNumber
      deviceType
      overrideAutoDiscovery
      partnerId
      msoServiceId    
    }
  }
}
`;

// MUTATIONS
export const DEVICE_ACCOUNT_FIX = gql`
  mutation($accountId: String!, $fipsCode: String!) {
    accountFix(accountId: $accountId, fipsCode: $fipsCode) 
  }
`;