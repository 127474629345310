
import { useQuery } from "@apollo/client";
import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { PPS_ACCOUNT_STATUS, PPS_CHECKED_IN_DEVICES } from "../tivo_gql";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";

const PpsAccountStatus = ({params}) => {

    const { loading, error, data } = useQuery(PPS_ACCOUNT_STATUS, {
        variables: {accountId: params.customerId }
    })
    const { loading: loadingPpsDevices, error: errorPpsDevices, data: ppsDevices } = useQuery(PPS_CHECKED_IN_DEVICES, { 
        variables: {accountId: params.customerId }
    })
    const localTime = (utcTime) => {
        let local_date= moment.utc(utcTime).local().format('ddd MM-DD-YYYY hh:mm a');
        return local_date
    }

    if (loading) return <Paper sx={{p: 3, m: 2}}><Box><div className="loading">Loading PPS Info...</div><CircularProgress/></Box></Paper>

    if (loadingPpsDevices) return <Paper sx={{p: 3, m: 2}}><Box><div className="loading">Loading PPS Devices...</div><CircularProgress/></Box></Paper>
 
    if(error) return (
        <Paper sx={{p: 3, m: 2}}>
            <Box>
            <Typography variant="h5">PPS Info</Typography>
                {error.message === "account_not_found" ? 'Account Not Found' : error.message}
            </Box>
        </Paper>
    )

    if(errorPpsDevices) return (
        <Paper sx={{p: 3, m: 2}}>
            <Box>
            <Typography variant="h5">PPS Info</Typography>
            {errorPpsDevices.message}
            </Box>
        </Paper>
    )
  
    if(data) {
        console.log('ppsdata: ', data)
        const ppsAccountInfo = data.ppsAccountStatusGet;
        const ppsDeviceInfo = ppsDevices.getCheckedInDevices

        return (
            <Paper sx={{p: 3, m: 2} }>
            <Box>
            <Typography variant="h5">PPS Info</Typography>
            <Typography paragraph={true} sx={{fontWeight: 'bold', mt: 1, mb: 1, pl: 1}} variant="p">Account Status</Typography>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    key={ppsAccountInfo.accountId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell>{ppsAccountInfo.accountId}</TableCell>
                    <TableCell>{ppsAccountInfo.customerId}</TableCell>
                    <TableCell>{ppsAccountInfo.status}</TableCell>
                    </TableRow>
 
                </TableBody>
            </Table>

        <Typography paragraph={true} sx={{fontWeight: 'bold', mt: 1, mb: 1, pl: 1}} variant="p">Devices</Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Device Type</TableCell>
                    <TableCell>First Check-in</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Last Check-in</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                 
                {ppsDeviceInfo === null ? <Typography sx={{marginTop: '10px'}}>No devices found</Typography> : 
                    ppsDeviceInfo.map((device) => (
                       <TableRow
                            key={device.serialNumber}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                            <TableCell>{device.deviceType}</TableCell>
                            <TableCell>{localTime(device.insertedAt)}</TableCell>
                            <TableCell>{device.serialNumber}</TableCell>
                            <TableCell>{device.status}</TableCell>
                            <TableCell>{localTime(device.updatedAt)}</TableCell>
                        </TableRow>
                    ))
                }
 
                </TableBody>
            </Table>

            </TableContainer>
        </Box>
        </Paper>
        )
    }
}

export default PpsAccountStatus