
import { useQuery } from "@apollo/client";
import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { TRINITY_CUSTOMER_INFO } from "../tivo_gql";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const TrinityCustomerInfo = ({params}) => {
  const { loading, error, data } = useQuery(TRINITY_CUSTOMER_INFO, {
    variables: {
      partnerCustomerId: params.customerId,
      contractStatus: "nil"
    } 
  })
  
  if (loading) return <Paper sx={{p: 3, m: 2} }><Box><div className="loading">Loading Trinity Info...</div><CircularProgress/></Box></Paper>

  if(error) return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {error.message}
    </Box>
  )

  if(data) {
    const trinityInfo = data.trinityCustomerInfoSearch.customerList[0];

    return (
      <Paper sx={{p: 3, m: 2} }>
      <Box>
              <Typography variant="h5">Trinity Info</Typography>
              <Typography variant="h6">Devices</Typography>

              {!trinityInfo.contractList.filter(contract => contract.status === "Active").length ? <Typography sx={{marginTop: '10px'}}>No active devices found</Typography> : 
              
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Hardware Serial Number</TableCell>
                        <TableCell>Tivo Serial Number</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
               
                      {trinityInfo.contractList
                        .filter(contract => contract.status === "Active")
                        .map((contract) => (
                        <TableRow
                          key={contract.device.tivoSerialNumber}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell><Link to={`/devices/deviceInfo/${contract.device.tivoSerialNumber}`}>{contract.device.hardwareSerialNumber}</Link></TableCell>
                          <TableCell><Link to={`/devices/deviceInfo/${contract.device.tivoSerialNumber}`}>{contract.device.tivoSerialNumber}</Link></TableCell>
                          <TableCell>{contract.status}</TableCell>
                        </TableRow>
                      ))

                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
            </Box>
            </Paper>
    )
  }
}

export default TrinityCustomerInfo