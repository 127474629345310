import React from 'react';

import { gql, useMutation } from '@apollo/client';

import { Button } from "@mui/material";

const M_LOGOUT = gql`
mutation {
  logout
}
`;

function applyLogin(loginHook, data) {
  loginHook({});
  window.location = data.logout;
}

function Logout({ loginHook }) {
  const [logout] =
    useMutation(M_LOGOUT,
      { onCompleted: (d => applyLogin(loginHook, d)) });

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={e => {
        logout();
      }}
    >
      Logout
    </Button>
  );
}

function Relogin() {

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={e => {
        window.location = window.location.origin;
      }}
    >
      Login
    </Button>
  );
}

export default Logout;
export { Relogin };
