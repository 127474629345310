import { useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { Box } from '@mui/system';

import Auth from './Auth';
import Logout, { Relogin } from './Logout';

const Q_STATUS = gql`
query {
  loginStatus {
    name
    email
    username
  }
}
`;

export default function LoginStatus(props) {
  const [login, setLogin] = useState({});
  const { loading, error, data } = useQuery(Q_STATUS);

  if (loading) {
    return (<div>Loading</div>);
  }

  if (error?.message === "must be logged in") {
    console.log("need login");
  } else if (error) {
    return (<div>`We have an error: ${error.message}`</div>);
  } else if (data?.loginStatus && data?.loginStatus?.email !== login?.email) {
    setLogin(data.loginStatus);
    return (<div>Processing Login</div>);
  }

  if (login?.email) {
    return (
      <Box>
        Logged in as {login.name} ({login.email})
        &nbsp;&nbsp;&nbsp;<Logout loginHook={setLogin} />
      </Box>
    );
  } else if (window.location.search === "?logout=true") {
    return (
      <Box>
        <div>
          <Relogin loginHook={setLogin} />
        </div>
        <div>
          Press login button to login again.
        </div>
      </Box>
    );
  } else {
    return (
      <Box>
        <Auth loginHook={setLogin} />
      </Box>
    );
  }
}