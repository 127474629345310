import React from 'react';

import { gql, useMutation } from '@apollo/client';

const M_LOGIN = gql`
mutation {
  authUrl
}
`;

function Auth() {
  const [login, { called }] = useMutation(M_LOGIN, {
    onCompleted: (d => applyLogin(d)),
    onError: (d => console.log(d))
  });

  if (window.location.search === "?logout=true") {
    return <div>Logged out</div>;
  } else if (!called) {
    login();
    return <div>Logging in...</div>;
  }
}

function applyLogin(data) {
  window.location.replace(data.authUrl);
}

export default Auth;